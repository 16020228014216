.drawer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 100px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
  margin: 5px 0px;
}

/* .leftHeader {
} */

.vipContainer {
  border: 2.2px solid;
  border-width: 2.2px;
  border-radius: 4px;
  border-image-slice: 1;
  border-image-source: linear-gradient(180deg, #5c7aa7 -1.27%, #313f63 101.28%);
  background: linear-gradient(180deg, #111031 -13.44%, #1131a4 71.19%),
    linear-gradient(180deg, #2c3055 -149.06%, #073386 176.29%, #6490ff 234.38%);
  background-blend-mode: screen;
}

.vipIcon {
  max-width: 50px;
  max-height: 50px;
  height: auto;
  aspect-ratio: 20/13;
  object-fit: contain;
}

.closeContainer {
  margin-left: auto;
  position: absolute;
  /* top: 20px; */
  right: 20px;
}

.closeButton {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: var(--mui-palette-secondary-main); */
}

.accordion {
  margin-bottom: 5px;
}

.iconContainer {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.iconImg {
  height: auto;
  /* margin-right: 10px; */
}

.subItem {
  display: flex;
  /* padding: 8px 0px 8px 45px; */
  gap: 5px;
}

.subIconImg {
  width: 16px;
  height: 16px;
}

:global(.active) .subItem {
  color: var(--mui-palette-text-txtSecondary);
  font-weight: 700;
}

.subItem:focus {
  background-color: #1c2257;
  border-radius: 36px;
}

.subIcon {
  height: auto;
  /* margin-right: 10px; */
}

.subIconList {
  margin-right: 6px;
  align-items: center;
  display: flex;
}

.typo {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: var(--mui-palette-text-primary);
}

.typotitle {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  margin-left: 3px;
  color: var(--mui-palette-text-primary);
}

.sidebarBottomContainer {
  background-color: var(--mui-palette-bg-bgSecondary);
  z-index: 999;
  bottom: 65px;
  position: fixed;
  width: 250px;
  height: 36px;
  padding: 15px 0;
}
.languageContainer {
  position: relative;
  color: var(--mui-palette-text-darkTextColor);
  font-size: 14px;
  border: 0.5px solid var(--mui-palette-border-darkBorderColor);
  border-radius: 60px;
  padding: 10px;
}

.item {
  background-color: #1c2257;
  border-radius: 57px;
  padding: 0px 8px;
}

.setting {
  /* background-color: #0d1134; */
  border-radius: 4px;
  padding: 10px 20px 0px 0px;
}

.settingItem {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: var(--mui-palette-text-primary);
  gap: 5px;
}

.itemImg {
  position: relative;
  align-items: center;
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
}

.itemTxt {
  width: auto;
  height: 20px;
  position: relative;
}

.gameBanner {
  display: flex;
  padding: 0px 4px 0px 6px;
  /* margin-left: -1%;
  margin-right: -2%; */
}

.gameBannerImg {
  float: left;
  height: auto;
  width: 47%;
  max-width: 100%;
  align-self: center;
  padding-right: 10px;
}

.gameItemName {
  font-size: 14px;
  font-weight: 400;
  margin-left: 3px;
  color: var(--mui-palette-text-primary);
}

.itemName {
  font-size: 14px;
  font-weight: 300;
  margin-left: 3px;
}

.banner {
  width: 100%;
  height: auto;
}

.tab {
  /* color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem; */
}

.tab div {
  margin-top: -3px;
  text-align: center;
}

:global(.active) .tab div {
  color: var(--mui-palette-text-txtSecondaryWhite);
  font-weight: 700;
}

.support {
  width: 48%;
  background-color: var(--mui-palette-bg-fourthBgColor);
  border-radius: 25px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.support img {
  margin-right: 10px;
}

.support span {
  font-size: 14px;
  color: var(--mui-palette-text-primary);
}

.cricketBg {
  width: 100%;
  height: auto;
  max-height: 1800px;
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 15px;
}

.downloadContainer {
  position: relative;
  padding-bottom: 10px;
}

.downloadBgImg {
  width: 100%;
  /* max-height: 65px; */
  height: auto;
}

.downloadContent {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.downloadImg {
  align-items: center;
  margin: 20px;
}

.downloadTxt {
  text-transform: uppercase;
  /* font-family: Rowdies; */
  font-size: 14px;
  font-weight: 700;
}

.liveSupportLangContext {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #4e566a;
  padding: 0 30px;
}

.banner_img {
  width: 100%;
  height: auto;
  margin-bottom: 3px;
}

.spinDiv {
  position: relative;
  /* display: flex; */
  /* height: auto;
  justify-content: center;
  margin-right: 10px; */
  height: fit-content;
  display: inline;
  padding: 0 10px;
}

.spinCount {
  position: absolute;
  top: 14%;
  right: 6%;
  display: inline-block;
}

.spinCountBg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .spinCountBg img {
  width: 100%;
  height: auto;
} */

.spinCountDiv {
  position: absolute;
  top: 20px;
  right: 25px;
}

.spinImg {
  height: auto;
  position: relative;
  top: 8px;
  right: 65px;
}

.spinCountTxtDiv {
  position: absolute;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  margin: 0;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.spinCountTxt {
  color: #000000;
  font-size: 12px;
  font-weight: 700;
  /* display: inline-block; */
}

.imgDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.liveSupport {
  bottom: 0px;
  position: absolute;
}

@media screen and (width > 520px) {
  .spinCount {
    top: 15%;
    right: 7%;
  }
  .spinCountBg img {
    width: 30px;
    height: 30px;
  }

  .spinCountTxt {
    font-size: 16px;
  }
}

/* @media screen and (width > 750px) {
  .spinDiv {
    width: 95%;
  }
}

@media screen and (max-width: 320px) {
  .spinDiv {
    width: 92%;
  }
} */
.dividerBorder {
  text-align: center;
  border-bottom: 1px solid transparent;
  border-image: var(--mui-palette-action-dividerLine);
  border-image-slice: 1;
  width: 98%;
}

/* gradientBorder {
  border-bottom: 1px solid;

  border-image-source: linear-gradient(90deg, #2C2958 0%, #5F59BE 49.5%, #2C2958 100%);
  
} */
