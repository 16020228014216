.container {
  width: 100%;
  min-height: 68px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
  color: var(--mui-palette-text-common);
  position: relative;
}

.icons {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 65px;
  align-items: center;
}

/* .icons img:first-child {
  align-self: flex-end;
  border-bottom-left-radius: 8px;
} */

.icons img:last-child {
  align-self: center;
}

.info {
  width: calc(100% - 65px);
  box-sizing: border-box;
  word-wrap: break-word;
  padding: 8px 30px 8px 3px;
}

.infoTitle {
  font-weight: 600;
  margin-bottom: 5px;
}

.infoContent {
  font-size: 0.9rem;
}

.close {
  position: absolute;
  top: 3px;
  right: 5px;
}

@media (min-width: 1001px) {
  .container {
    width: 600px;
  }
}
