.container-home {
  margin-bottom: 15px;
  min-height: 210px;
  position: relative;
  z-index: 10;
}

.container-home iframe {
  position: absolute;
  z-index: 1;
  /* background-color: var(--mui-palette-bg-primary); */
}

.container-game {
  height: calc(100vh - 125px);
}

.tsb-img {
  width: 100%;
  height: auto;
}

.skeleton {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, 0%);
  margin: 15px 0 0;
  border-radius: 8px;
}

.imgWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 25px;
}

.leftTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.leftTitle span {
  margin-left: 4px;
  color: var(--mui-palette-text-primary);
  font-weight: 500;
  font-size: var(--text-xl);
}

.default-img-icon {
  max-width: 20px;
  max-height: 20px;
}
