.wrapper {
  background-color: black;
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  outline: none;
}

.container {
  width: 100vw;
  height: 100%;
  border: 0px;
  box-sizing: border-box;
}

.header {
  /* position: fixed; */
  width: 100%;
  height: 56px;
  background-color: var(--mui-palette-bg-primary);
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}

.iconWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 10px;
}

.wrapper :global(.header) {
  max-width: unset !important;
}

.headerLogo {
  margin-bottom: 4px;
}
