.container {
  background-color: var(--mui-palette-bg-primary);
  min-height: 100vh;
  background-size: cover;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  padding: 0;
}

.customBg {
  background-color: var(--mui-palette-bg-quaternary);
}

.contentContainer {
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 100% auto;
  /* background-color: var(--mui-palette-bg-primary); */
  max-width: 700px;
  margin: auto;
  position: relative;
}

.bonusContentNonImgBg {
  background-image: none !important;
}

.contentContainerNonBg {
  box-sizing: border-box;
  max-width: 700px;
  margin: auto;
  position: relative;
}

.wrapper {
  box-sizing: border-box;
  padding: 0 17px 90px;
  max-width: 600px;
  margin: auto;
}

.wrapper3 {
  padding: 0 0px;
}

.sportsWrapper {
  box-sizing: border-box;
  padding: 0 0 60px;
  max-width: 600px;
  margin: auto;
}