.header {
  position: fixed;
  width: 100%;
  z-index: 100;
  height: 45px;
  display: flex;
  box-sizing: border-box;
  max-width: 700px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* top: -1px !important; */
}

.joinSnack {
  background-color: var(--mui-palette-component-topSnackBar-welcomeBonusBg);
}
.affiliateSnack {
  background-color: var(--mui-palette-component-topSnackBar-affiliateBg);
}

.joinBtn {
  background-color: var(--mui-palette-bg-primary);
  border-radius: 24px;
  width: 80px;
  height: 26px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.joinSpan {
  color: var(--mui-palette-text-primary);
  text-transform: uppercase;
  font-size: var(--text-sm);
  font-weight: 700;
}

.affiliateTitle {
  text-align: center;
}

.leftHeader {
  color: var(--mui-palette-text-common);
  font-size: var(--text-sm);
  font-weight: 600;
  padding: 10px;
  width: 100%;

}

.leftHeaderDiv {
  text-align: center;
}

.inviteRight {
  position: absolute;
  top: 10px;
  right: 10px;
}

.rightHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  right: 10px;
  position: relative;
}

.registerBtn {
  position: relative;
  z-index: 1;
  width: 100px;
  height: 30px;
  border-radius: 22px;
  display: flex;
  align-items: center;
}

.welcomeBonusIcon {
  top: 4px;
  position: relative;
}

.btnText {
  width: 100%;
  height: 100%;
  line-height: 30px;
  font-weight: 600;
  color: var(--mui-palette-text-secondary);
}

/* .snackbar {
  height: 58px;
  background-color: #16c87c;
} */

@media screen and (max-width: 320px) {
  .leftHeader {
    font-size: 10px;
  }
}
