.container {
  background-size: cover;
  box-sizing: border-box;
  background-color: var(--mui-palette-bg-primary);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  min-height: 100dvh;
  height: 100%;
  padding: 23px 14px 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
