.container {
  width: 48%;
  position: relative;
}

.flag {
  height: auto;
  border-radius: 20px;
}

.menuText {
  margin-left: 5px;
  color: var(--mui-palette-text-secondary);
  font-size: var(--text-md);
  letter-spacing: 0.04em;
}

.langPadding .menuText {
  color: var(--mui-palette-text-secondary);
}

.menuContainer {
  width: 100%;
  position: absolute;
  bottom: 40px;
}

.menuItem {
  width: 100%;
  position: relative;
}

.languageOptionContainer {
  position: relative;
  font-size: var(--text-md);
  /* border-radius: 60px; */
  padding: 0px 10px;
  /* width: 110px; */
  margin-left: auto;
  background-color: var(--mui-palette-bg-tertiary);
  border-radius: 6px;
}

.languageOptionExpand {
  position: relative;
  font-size: var(--text-md);
  /* border-radius: 27px; */
  border-radius: 6px;
  /* margin-left: auto; */
  background-color: var(--mui-palette-bg-tertiary);
  /* background-color: var(--mui-palette-bg-secondary); */
}

.langPadding {
  padding: 10px;
  background-color: var(--mui-palette-bg-secondary);
  border-radius: 4px;
  margin-bottom: 10px;
}

.toggleMenuContainer {
  display: flex;
  align-items: center;
}

.toggleMenuExpand {
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.icon {
  right: 12px;
  position: absolute;
  color: var(--mui-palette-icon-primary);
}

.listIcon {
  right: 6px;
  position: absolute;
}

.menuItemContainer {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 2px;
}
