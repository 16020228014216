/*transform is used to fix page flicking on ios */
.header {
  position: fixed;
  width: 100%;
  z-index: 999;
  text-align: center;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;
  box-sizing: border-box;
  background-color: var(--mui-palette-bg-navBg);
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 700px;
  margin: auto;
  transform: translate(0, 0);
}

.hasTop {
  top: 45px;
}

.noneTop {
  top: -1px;
}

.homePageSection {
  border-bottom: 1px solid var(--mui-palette-border-thirdBorderColor);
}

.leftHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.leftHeader a {
  display: block;
  /* height: 30px; */
}

.logo {
  height: auto;
  margin-top: 9px;
}

.logoBack {
  height: auto;
  margin-left: 30px;
  margin-top: 9px;
}

.accountDetails {
  display: flex;
  align-items: center;
  gap: 5px;
}

.beforeLogin {
  display: flex;
  align-items: center;
  /* gap: 5px; */
}

.login {
  color: var(--mui-palette-text-white);
  position: relative;
  z-index: 1;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  /* background: var(--mui-palette-bg-action2) !important;
  border: 1px solid var(--mui-palette-border-action1) !important; */
}

.registerBtn {
  position: relative;
  z-index: 1;
  width: 90px;
  height: 34px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background: var(--mui-palette-bg-action1) !important;
}

.register {
  position: absolute;
  z-index: -1;
}

.btnText {
  width: 100%;
  height: 100%;
  line-height: 30px;
  font-weight: 600;
  color: var(--mui-palette-text-white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.backArrow {
  top: 17px !important;
}

.navLink {
  margin:10px 10px 0px 0px;
}