.coin {
  height: auto;
  width: 20px;
  position: absolute;
  left: 5px;
}

.memberBalance {
  color: var(--mui-palette-text-link);
  font-size: var(--text-sm);
  font-weight: 500;
  margin-left: 25px;
  margin-right: 30px;
  line-height: 12px;
  height: 11px;
}

.memberWallet {
  /* margin-top: 20px; */
  position: relative;
  background: var(--mui-palette-bg-quaternary);
  border-radius: 6px;
  min-width: 130px;
  margin-right: 10px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.walletBtn {
  position: absolute;
  /* top: -2px; */
  right: -5px;
  background-image: var(--mui-palette-bg-action1);
  width: 35px;
  height: 100%;
  border-radius: 6px;
}

.wallet {
  position: absolute;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
