.accountIcon {
  color: var(--mui-palette-text-secondary);
  font-size: 28px !important;
}

.dropdownHeader {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background-color: var(--mui-palette-bg-quaternary);
}

.nickName {
  font-size: var(--text-lg);
  font-weight: 600;
  word-break: break-all;
  color: var(--mui-palette-text-white);
}

.vipprogress {
  font-size: 10px;
  color: var(--mui-palette-text-white);
  /* padding-top: -5px; */
  display: flex;
}

.vipIconFrame {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vipSmall {
  height: auto;
}

.vipIconSmall {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 23px;
  height: 23px;
}

.vipIconBig {
  position: absolute;
  display: flex;
  height: auto;
  /* top: 55%;
  transform: translateY(-50%); */
}

.iconBig4,
.iconBig5,
.iconBig6,
.iconBig7,
.iconBig8,
.iconBig9,
.iconBig10 {
  margin-top: 4px;
}

.menuItem1 {
  background-color: var(--mui-palette-bg-fifth);
  border-radius: 8px;
  color: var(--mui-palette-text-primary) !important;
}

.menuItem1 .MuiListItemIcon-root {
  color: var(--mui-palette-text-primary) !important;
}

.menuItem2 {
  background-color: var(--mui-palette-bg-tertiary);
  border-radius: 8px;
  margin-top: 8px;
  color: var(--mui-palette-text-primary) !important;
}

/* .dropdownHeaderCol { */
/* display: flex; */
/* flex-direction: column; */
/* align-items: center; */
/* } */

.vipProgressTxt {
  font-size: 10px;
  color: var(--mui-palette-text-white);
  display: flex;
  align-items: center;
  
}

.vipProgressSym {
  margin: 0 2px;
}

.vip {
  line-height: 13px;
  height: 12px;
  font-family: 'OpenSans', sans-serif;
  color: var(--mui-palette-text-primary) !important;
}
